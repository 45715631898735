import React, { useEffect, useState } from "react";
// import { useFormContext, Controller } from "react-hook-form";
// mui imports
import FormControl from "@mui/material/FormControl";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import {  Typography,Paper } from '@material-ui/core';
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker, LocalizationProvider,TimePicker } from '@mui/x-date-pickers';
// import { DatePicker, LocalizationProvider, TimePicker } from "@mui/lab";
import Chip from "@mui/material/Chip";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { compareStrings } from "../../utils/services";
import Button from "@mui/material/Button";

// mockdata

const BasicDetails = (props) => {
  const { InspectorChange, inspList, getDateTime, selectedInspectors, inspectionId, editValues, Type, editRefresh} = props;

  const [date, setDate] = useState(moment());
  const [time, setTime] = useState(moment().valueOf());
  const [dateopen, setDateOpen] = React.useState(false);
  const [timeopen, setTimeOpen] = React.useState(false);

      const useStyles = makeStyles(theme => ({
          inputRoot: {
            "& .MuiOutlinedInput-notchedOutline": {
              height: selectedInspectors.length === 0 && '45px',
              paddingTop: selectedInspectors.length === 0 && '10px'  
          
            },
          }
      }));

      useEffect(() => {
        if(Type === 'edit'){
          setDate(editValues.start_time)
          setTime(editValues.start_time)
        }
      }, [editRefresh])
  const UTCConvertion = (date) => {
    const UTC = moment.utc(date).local().format();
    return UTC
  }
     
  const onKeyDown = (e) => {
    e.preventDefault();
 };

  const timestramp =
    moment(date).format("YYYY-MM-DD") + " " + moment(time).format("HH:mm");
    getDateTime(timestramp);

  const classes = useStyles();
  return (
    <>
      <Grid container className="input-controll">
        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" className="forms-control">
            <label
              className="input-form-label"
              htmlFor="Inspection ID"
              // id="demo-simple-select-label"
            >
              Inspection ID
            </label>
            <TextField
              className="input-textfield disabled-input"
              size="small"
              onChange={(e) => e.preventDefault()}
              // disabled
              variant="outlined"
              value={ Type === undefined ? '' : Type === 'schedule' ?  inspectionId !== undefined ? `#`+ inspectionId : '' : Type === 'edit' && `#` + editValues.id}
              // placeholder="#2003"
              type="text"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          
          <label
            className="input-form-label pl-15"
            htmlFor="Inspectors"
            id="inspector-field"
            style={{ display: "block" }}
          >
            Inspectors*
          </label>
          <Autocomplete
                 className="forms-control custom-color"
                 id="tags-outlined"
                 classes={classes}
                 filterSelectedOptions
                //  freeSolo
                 multiple
                 size="small"
            options={selectedInspectors?.length <= 1 ? inspList : []}
            value={selectedInspectors}
            getOptionLabel={(option) => option?.name}
            onChange={(e, value) => InspectorChange(value)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  deleteIcon={<CloseIcon />}
                  label={option?.name}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}

                //stop typing after selection of 2 values from the dropdown 
                inputProps={{
                  ...params.inputProps,
                  onKeyPress: (e) => {
                    if (selectedInspectors.length > 1) {
                      e.preventDefault();
                    } else {
                      return true;
                    }
                  },
                }}
                //stop blinking of cursor after selection of 2 values 
                sx={
                  selectedInspectors.length > 1
                    ? { caretColor: "transparent" }
                    : { caretColor: "black" }
                }
                // onChange={({ target }) => InspectorChange(target.value)}
                placeholder={selectedInspectors.length <= 1 ?"Select Inspector":""}
                // error={inspValid === 1 && true}
                // helperText={inspValid === 1 && 'please select the field'}
              />
            )}
            // noOptionsText={'No Options'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" className="forms-control">
            <label
              className="input-form-label"
              htmlFor="date"
              id="demo-simple-select-label"
            >
              Date*
            </label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={date}
                open={dateopen}
                onOpen={() => setDateOpen(true)}
                onClose={() => setDateOpen(false)}
                format="DD-MM-YYYY"
                // className={"inspection-filter-modal-select"}
                onChange={(newValue) => {
                  setDate(newValue);
                }}
                renderInput={(params) => <TextField onKeyDown={onKeyDown}  InputLabelProps={{
                  shrink: true
                }} onClick={(e) => setDateOpen(true)} {...params} size="small" />}
                // error={Boolean(errors.date)}
                // helperText={errors.date?.message}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" className="forms-control">
            <label
              className="input-form-label"
              htmlFor="time"
              id="demo-simple-select-label"
            >
              Time*
            </label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
               open={timeopen}
               onOpen={() => setTimeOpen(true)}
               onClose={() => setTimeOpen(false)}
                value={time}
                // ampm={false}
                onChange={(newValue) => {
                  setTime(newValue);
                }}
                renderInput={(params) => <TextField
                  onClick={(e) => setTimeOpen(true)}
                  {...params}
                  onKeyDown={onKeyDown}
                  inputProps={{
                  ...params.inputProps,
                  placeholder:"hh:mm am/pm"
                }}   size="small" />}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default BasicDetails;
