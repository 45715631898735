import React, { useEffect, useRef } from "react";
import { Autocomplete, Chip, FormControl, Grid, SvgIcon, TextField } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { arrowDown } from "../../../../assets/svgIcons/Svg";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Box } from "@mui/system";
import FroalaEditorComponent from "../../../common/htmlEditor/FroalaEditorComponent";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
const EditCategorys = (props) => {
  const editor = useRef();
  const { linedata, from, AllCategoryList, editableData, commentListData, setCommentTags, commentTags } = props;
  const isNarrative = AllCategoryList?.template?.is_narrative_template;
  const [name, setName] = React.useState(linedata.prompt);
  const [isOptional, setIsOptional] = React.useState(linedata.optional === 0 ? false : true);
  const [introductory, setIntroductory] = React.useState(isNarrative ? { innerHTML: linedata?.introductory_text?.replace(/<[^>]+>\s*<\/[^>]+>/g, '')} : linedata?.introductory_text);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleIntroductry = (content) => {
    setIntroductory(content);
  };
  const handleOptional = (event) => {
    setIsOptional(event.target.checked ? 1 : 0);
  };
  useEffect(() => {
    const initialTags = linedata?.narrative_categories_tags?.map((item) => ({
      id: item.tag.id,
      title: item.tag.name,
      color: item.tag.color,
    }));
    setCommentTags(initialTags);
  }, [linedata, setCommentTags]);

  editableData(name, isNarrative ? introductory?.innerHTML : introductory, isOptional);

  const handleEditorChange = (name, content) => {
    setIntroductory({ innerHTML: content });
  };
  const handleTagChange = (event, selectedTag) => {
    setCommentTags(selectedTag);
  };

  const isSelected = (tagId) => {
    return commentTags.some((tag) => tag.id === tagId);
  };

  return (
    <>
      <Grid container style={{ paddingTop: "34px" }} spacing={2} className="inspection-filter-modal-margins">
        <Grid item lg={11.4} xs={11.4} sm={11.4}>
          <FormControl fullWidth>
            <label className="inspection-filter-modal-label" sx={{ mb: "12px" }}>
              NAME *
            </label>
            <TextField style={{ paddingTop: "0px !important" }} type="text" size="small" name="name" placeholder="Detail name" onChange={(e) => setName(e.target.value)} value={name} />
          </FormControl>
        </Grid>
        {AllCategoryList?.template?.is_narrative_template === 1 && (
          <Grid item lg={11.4} xs={11.4} sm={11.4}>
            {from.split("_")[0] === "CATEGORY" && (
              <FormControl fullWidth>
                <label className="inspection-filter-modal-label" sx={{ mb: "12px" }}>
                  LIBRARY TAGS
                </label>
                <Autocomplete
                  className="forms-control1 autoHover autocompleteCustom"
                  id="tags-outlined"
                  multiple
                  size="small"
                  options={commentListData}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option?.title}
                  openOnFocus
                  value={commentTags}
                  open={open}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ccc",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ccc",
                      },
                    },
                    marginBottom: "-22px !important",
                  }}
                  onChange={(e, value) => {
                    setCommentTags(value);
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        className="tag-custon-chip"
                        variant="outlined"
                        deleteIcon={<CloseOutlinedIcon />}
                        label={option?.title}
                        {...getTagProps({ index })}
                        sx={{
                          padding: "2px 2px 2px 10px !important",
                          fontSize: "10px !important",
                        }}
                      />
                    ))
                  }
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      key={props?.key + props?.id}
                      onClick={() => {
                        const isAlreadySelected = isSelected(option.id);
                        const newTags = isAlreadySelected ? commentTags.filter((tag) => tag.id !== option.id) : [...commentTags, option];
                        handleTagChange(null, newTags);
                      }}
                      style={{ height: "36px" }}
                    >
                      <Checkbox style={{ borderRadius: "50%", fontSize: "14x", borderColor: "#CED2DA", paddingRight: "6px" }} checked={isSelected(option.id)} />
                      <Box
                        sx={{ width: "12px", height: "12px", backgroundColor: option?.color, borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}
                        style={{
                          marginRight: 8,
                        }}
                      />
                      {option?.title}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={commentTags?.length === 0 ? "Tag name" : ""}
                      onClick={!open ? handleOpen : handleClose}
                      onBlur={handleClose}
                      InputProps={{
                        ...params.InputProps,
                        style: { fontFamily: "Open Sans!important", fontSize: "14px", fontWeight: 400 },
                        endAdornment: (
                          <>
                            <SvgIcon className={`customArrowDown ${open ? "arrowActive" : ""}`} viewBox="" onClick={!open ? handleOpen : handleClose}>
                              {arrowDown}
                            </SvgIcon>
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            )}
            <p style={{ marginTop: "28px", fontSize: "12px", color: "#6D7278" }}>Select one or more tags to use as the default filter when you open this category in the report writer. </p>
          </Grid>
        )}
        <Grid sx={{ mt: "12px" }} item lg={11.4} xs={11.4} sm={11.4}>
          <Grid sx={{ mt: "12px" }} item lg={11.4} xs={11.4} sm={11.4}>
            <FormControl fullWidth>
              <FormGroup>
                <FormControlLabel control={<Checkbox checked={isOptional} onChange={handleOptional} />} label={<p style={{ fontSize: "14px" }}>Optional</p>} />
              </FormGroup>
              <div style={{ fontSize: "12px" }}>
                {` If you mark the ${from.split("_")[0] === "CATEGORY" ? "category" : "line"}  as optional, the ${
                  from.split("_")[0] === "CATEGORY" ? "category" : "line"
                } will not be automatically included in your report. You will be able to manually add it to an inspection when needed.`}
              </div>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default EditCategorys;
