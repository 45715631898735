export function deepCompare(obj1, obj2) {
    if (typeof obj1 === 'object' && typeof obj2 === 'object' && obj1 !== null && obj2 !== null) {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);  
      if (keys1.length !== keys2.length) {
        return false;
      }  
      for (let key of keys1) {
        if (!obj2.hasOwnProperty(key)) {
          return false;
        }  
        if (!deepCompare(obj1[key], obj2[key])) {
          return false;
        }
      }  
      return true; 
    } else {
      return obj1 === obj2;
    }
  }

export function updateJSON(obj, targetUUID, newValue,updateType) {
  if (Array.isArray(obj)) {
    obj.forEach((item) => {
      updateJSON(item, targetUUID, newValue,updateType);
    });
  } else if (typeof obj === "object" && obj !== null) {
    if (obj.uuid === targetUUID) {
      if(updateType === "label"){
        obj.label = newValue;
      }
      else if(updateType === "value"){
        obj.value = newValue;
      }     
      else if(updateType === "tag"){
        obj.merge_id = newValue;
      }     
      else if(updateType === "radioElement"){
        obj.elements = newValue;
      }     
    }
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        updateJSON(obj[key], targetUUID, newValue,updateType);
      }
    }
  }
}

// Function to change update element object based on user input
export function updateElementJsonByUUID(jsonObj, targetUUID, newValue,updateType) {
  function findTarget(jsonObj) {
    if (Array.isArray(jsonObj)) {
      for (const item of jsonObj) {
        if (item.uuid === targetUUID) {
          if(updateType === "label"){
            item.label = newValue;
          }
          else if(updateType === "type"){
            item.type = newValue;
          }
          else if(updateType === "delete"){
            item.is_deleted = newValue;
          }          
          else if(updateType === "infoType"){
            item.type = newValue;
            item.value = '';
          }          
          else if(updateType === "tag"){
            item.merge_id = newValue;
          }          
          return true; 
        }
        if (findTarget(item)) {
          return true; 
        }
      }
    } else if (typeof jsonObj === "object" && jsonObj !== null) {
      for (const key in jsonObj) {
        if (jsonObj.hasOwnProperty(key)) {
          if (findTarget(jsonObj[key])) {
            return true; 
          }
        }
      }
    }
    return false; 
  }
  const labelUpdated = findTarget(jsonObj);
  if (labelUpdated) {
    return jsonObj; 
  } else {
    return null; 
  }
}

// Function to remove an element object by UUID
export function removeElementByUUID(jsonObj, targetUUID) {
  function findAndRemoveTarget(jsonObj) {
    if (Array.isArray(jsonObj)) {
      for (let i = 0; i < jsonObj.length; i++) {
        if (jsonObj[i].uuid === targetUUID) {
          jsonObj.splice(i, 1);
          return true; 
        }
        if (findAndRemoveTarget(jsonObj[i])) {
          return true; 
        }
      }
    } else if (typeof jsonObj === "object" && jsonObj !== null) {
      for (const key in jsonObj) {
        if (jsonObj.hasOwnProperty(key)) {
          if (findAndRemoveTarget(jsonObj[key])) {
            sessionStorage.setItem("firstLevelDrag", false);
            return true;
          }
        }
      }
    }
    return false; 
  }
  const removed = findAndRemoveTarget(jsonObj);
  if (removed) {
    return jsonObj; 
  } else {
    return null;
  }
}

// function to deleteElements
export const deleteElement = (templateState, setTemplateState, parentType, uuid, catuuid, updatePtTempateData,handleClose) => {
  let prevData = JSON.parse(JSON.stringify(templateState?.templateDataCopy));
  setTemplateState(prev => ({ ...prev, updatingTemplate: true }));

  let updatedData;
  if (parentType === 'category') {
    updatedData = updateElementJsonByUUID(templateState?.templateDataCopy.data, uuid, true, 'delete');
  } else {
    updatedData = removeElementByUUID(templateState?.templateDataCopy.data, uuid);
  }
  if (updatedData) {
    prevData.data = updatedData;
    setTemplateState(prev => ({ ...prev, templateData: prevData }));
    let catIdx = prevData?.data?.template?.pdf_categories.findIndex(el => el.uuid === catuuid);
    let editedCategory = [prevData.data.template.pdf_categories[catIdx]];
    let newUpdatedSpecificData = JSON.parse(JSON.stringify(prevData));
    newUpdatedSpecificData.data.template.pdf_categories = editedCategory;
    updatePtTempateData(newUpdatedSpecificData?.data?.template);
  }
  handleClose();
};

export function findMergeIds(obj) {
  const mergeIds = [];
  function traverseObject(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (key === "merge_id" && obj[key] !== null) {
          mergeIds.push(obj[key]);
        } else if (typeof obj[key] === "object" && obj[key] !== null) {
          traverseObject(obj[key]);
        }
      }
    }
  }
  traverseObject(obj);
  return mergeIds;
}

export function innerElCount(data) {
  let count = 0;

  if (!data) {
    return count;
  }

  // if (Array.isArray(data)) {
  //   data.forEach((item) => {
  //     count++;
  //     if (item.elements) {
  //       count += innerElCount(item.elements); 
  //     }
  //   });
  // }
  if (Array.isArray(data)) {
    data.forEach((item) => {
      count++; 
      if (item.elements && item.type !== "Layout/Table") {
        count += innerElCount(item.elements);
      }
    });
  }

  return count;
}


export function findIndexByUUID(dataObj, targetUUID) {
  const stack = [dataObj];  
  while (stack.length > 0) {
      const current = stack.pop();      
      if (current.uuid === targetUUID) {
          return 0;
      }      
      if (current.elements && current.elements.length > 0) {
          for (let i = 0; i < current.elements.length; i++) {
              const child = current.elements[i];
              if (child.uuid === targetUUID) {
                  return i;
              }
              if (child.elements) {
                  stack.push(child);
              }
          }
      }
  }  
  return -1;
}
export function findParentUUID(data, childUUID) {
  function search(categories, childUUID, parentUUID) {
    if (!Array.isArray(categories)) {
      return null;
    }
    for (const category of categories) {
      if (category.uuid === childUUID) {
        return parentUUID; 
      } else if (category.elements && category.elements.length > 0) {
        const parentUUID = search(category.elements, childUUID, category.uuid);
        if (parentUUID) {
          return parentUUID;
        }
      }
    }
    return null;
  }

  return search(data.pdf_categories, childUUID, null); 
}

export function isMergeIdNull(obj) {
  if (obj === null) {
    return false;
  }
  
  if (typeof obj === 'object') {
    if (obj.hasOwnProperty('merge_id') && obj.merge_id === null) {
      return true;
    }
    const values = Object.values(obj);
    for (const value of values) {
      if (isMergeIdNull(value)) {
        return true;
      }
    }
  } else if (Array.isArray(obj)) {
    return obj.some(item => isMergeIdNull(item));
  }
  
  return false;
}


export function hasDuplicateEntries(arr) {
  const seen = new Set();
  return arr.some((item) => {
    const isDuplicate = seen.has(item);
    seen.add(item);
    return isDuplicate;
  });
}

export function hasEmptyStrings(arr) {
  return arr.some(str => str === '');
}

export function filterSelectTagIntro(htmlText) {
  var container = document.createElement("div");
  container.innerHTML = htmlText || "";
  var selects = container.getElementsByTagName("select");
  for (var i = selects.length - 1; i >= 0; i--) {
    var select = selects[i];
    if (select.id) {
      var selectId = select.id;
      var placeholder = `[dropdown ${selectId}]`;
      select.outerHTML = placeholder;
    }
  }
  container.innerHTML = container.innerHTML.replace(/\s+/g, " ").trim();
  return container.innerHTML || "";
}
